<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                        <h6>Members List</h6>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                        <div class="table-responsive p-0">

                            <!-- <div class="m-4">
                                <b-row>

                                    <b-col cols="12">
                                        <div class="d-flex align-items-center justify-content-end">
                                            <b-form-input
                                                class="d-inline-block mr-1"
                                                placeholder="Search"
                                                :disabled="loading"
                                            />
                                        </div>
                                    </b-col>

                                    <b-col cols="12" md="2"
                                        class="d-flex align-items-end justify-content-end mb-1 mb-md-0">
                                        <soft-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="gradient"
                                            color="primary" class="d-block w-100">
                                            <span>Add New Member</span>
                                        </soft-button>
                                    </b-col>

                                </b-row>
                            </div> -->

                            <b-table
                                :items="members"
                                :fields="[
                                    { key: 'name', label: 'Name', sortable: true },
                                    { key: 'email', label: 'Email', sortable: false, class: 'text-center' },
                                    { key: 'phone', label: 'Phone', sortable: false, class: 'text-center' },
                                    { key: 'gender', label: 'Gender', sortable: false, class: 'text-center' },
                                    { key: 'username', label: 'Profile', sortable: false, class: 'text-center' },
                                    { key: 'created_at', label: 'Received', sortable: true, class: 'text-center' },
                                    // { key: 'id', label: '', sortable: false, class: 'text-center' },
                                ]"
                                hover
                                responsive
                                show-empty
                                per-page="20"
                                :busy="loading"
                                primary-key="id"
								ref="table"
								select-mode="multi">

                                <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <p>
                                            <strong>Loading...</strong>
                                        </p>
                                    </div>
                                </template>

                                <template #cell(name)="{ item }">
                                    <router-link :to="{ name: 'MemberInfo', params: { id: item?.member?.id } }">
                                        <div class="d-flex px-2 py-1">
                                            <soft-avatar
                                                :img="item.avatar"
                                                type="member"
                                                size="sm"
                                                border-radius="lg"
                                                class="me-3"
                                                :alt="item?.name"
                                            />
                                            <div class="d-flex flex-column justify-content-center">
                                                <h6 class="mb-0 text-sm">{{ item?.name || 'N/A' }}</h6>
                                                <p class="text-xs text-secondary mb-0">{{ item?.member?.type?.name || 'N/A' }}</p>
                                            </div>
                                        </div>
                                    </router-link>
                                </template>

                                <template #cell(email)="{ item }">
                                    <soft-button
                                        v-if="item?.email"
                                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        v-b-tooltip.hover.v-success
                                         v-clipboard:copy="item?.email"
                                        v-clipboard:success="() => onCopy('Email Copied Successfully')"
                                        variant="gradient"
                                        circle
                                        color="success"
                                        :title="item?.email">
                                        <i class="fa fa-envelope-o"></i>
                                    </soft-button>
                                    <span v-else>N/A</span>
                                </template>

                                <template #cell(phone)="{ item }">
                                    <soft-button
                                        v-if="item?.phone"
                                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        v-b-tooltip.hover.v-primary
                                        v-clipboard:copy="item?.phone"
                                        v-clipboard:success="() => onCopy('Phone Copied Successfully')"
                                        variant="gradient"
                                        circle
                                        color="primary"
                                        :title="item?.phone">
                                        <i class="fa fa-phone"></i>
                                    </soft-button>
                                    <span v-else>N/A</span>
                                </template>

                                <template #cell(gender)="{ item }">
                                    <span class="text-center">{{ item?.member?.gender || 'N/A' }}</span>
                                </template>

                                <template #cell(username)="{ item }">
                                    <a
                                        v-if="item.username"
                                        tag="button"
										v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        variant="gradient"
										class="btn mb-0 bg-gradient-danger btn-md btn-circle lh-40"
										:href="`${UI_URL}u/${item.username}`"
										target="_blank"
                                        :title="item.name"
                                    >
                                        <i class="fa fa-link"></i>
                                    </a>
                                    <span v-else>N/A</span>
                                </template>

                                <template #cell(created_at)="{ item }">
                                    <span class="text-center">{{ $moment(item.created_at).format('MM/DD/YYYY') }}</span>
                                    <br>
                                    <span class="text-center">{{ $moment(item.created_at).format('h:mm A') }}</span>
                                </template>

                                <template #cell(id)="{ item }">
                                    <soft-button
                                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        variant="gradient"
                                        circle
                                        color="info"
                                        @click="onCopy(item)"
                                        v-b-tooltip.hover.v-info
                                        title="Edit">
                                        <i class="fa fa-edit"></i>
                                    </soft-button>

                                    <soft-button
                                        class="mx-1"
                                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        variant="gradient"
                                        circle
                                        color="danger"
                                        @click="onCopy(item)"
                                        v-b-tooltip.hover.v-danger
                                        title="Delete">
                                        <i class="fa fa-trash"></i>
                                    </soft-button>
                                </template>

                            </b-table>

                        </div>
                    </div>
                </div>

                <pagination
                    v-if="!loading"
                    :currentPage="pagination.current_page"
                    :lastPage="pagination.last_page"
                    @paginate="paginate($event)"
                />

            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex'
import { useToast } from 'vue-toastification'
import SoftBadge from '@/components/SoftBadge'
import SoftAvatar from '@/components/SoftAvatar'
import Pagination from '@/components/Pagination'
import SoftButton from '@/components/SoftButton'
import {
    BCol,
    BRow,
    BTable,
    BButton,
    BSpinner,
    BFormInput,
} from 'bootstrap-vue'

export default {
    name: 'members',

    props: ['id'],

    setup() {
        // Get toast interface
        const toast = useToast()

        // Make it available inside methods
        return { toast }
    },

    data() {
        return {
            selected: [],
            loading: true,
            pagination: {
				per_page: this.$route?.query?.limit || 20,
				current_page: this.$route?.query?.page || 1,
				last_page: 100,
			}
        }
    },

    components: {
        BRow,
        BCol,
        BTable,
        BButton,
        BSpinner,
        SoftBadge,
        SoftAvatar,
        SoftButton,
        Pagination,
        BFormInput,
    },

    computed: {
        ...mapState({
            members: state => state.member.members,
        }),
    },

    beforeMount() {
        this.getData()
    },

    methods: {

        ...mapActions({
            getMembers: `member/getMembersAction`,
        }),

        getData() {
            this.getMembers({ ...this.pagination }).then(({ data }) => {
				this.pagination = this.setCurrentPagePagination(data)
			}).finally(() => {
				this.loading = false
			})
        },

        paginate(event) {
			this.loading = true

            this.pagination.current_page = event
			this.$router.push({
                params: {
                    id: this.id
                },
				query: {
					...this.$route.query,
					page: event
				}
			})

            this.getData()
        },

        onCopy(message, type = 'success') {
            this.toast[type](message)
        },

    },
}
</script>
